<template>
  <b-overlay :show="loading">
    <b-row>
      <div class="col-12 col-lg-10">
        <div
          class="card card-body"
          style="height: 100%; margin: 0px !important"
        >
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="device.avatar"
                  size="104px"
                  style="background: #dddddd"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h2 class="mb-0">
                      {{ device.title }}
                      <br />
                      <span class="text-muted">{{ device.serial_number }}</span>
                    </h2>
                    <span class="card-text">{{ device.email }}</span>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      :to="'/devices/edit/' + device._id"
                      variant="success"
                      class="btn-relief-success"
                    >
                      <i class="fa fa-edit text-dark"></i>
                      {{ e("edit") }}
                    </b-button>
                    &nbsp;
                    <b-button disabled>
                      <i class="fa fa-trash text-danger"></i>
                      {{ e("delete") }}
                    </b-button>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div
                class="d-flex align-items-center mt-2 table-responsive"
                style="overflow-y: hidden"
              >
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="UsersIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ e("users") }}</h5>
                    <small>{{ device.users }}</small>
                  </div>
                </div>
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-success" rounded>
                    <feather-icon icon="HashIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ e("exists-fingerprints") }}</h5>
                    <small>{{ device.fingerprints }}</small>
                  </div>
                </div>
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-info" rounded>
                    <feather-icon icon="FileTextIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ e("exists-records") }}</h5>
                    <small>{{ device.records }}</small>
                  </div>
                </div>
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-danger" rounded>
                    <feather-icon icon="TerminalIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">{{ e("pending_records") }}</h5>
                    <small>{{ device.pending_records }}</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="EditIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ e("version") }}</span>
                  </th>
                  <td class="pb-50">
                    {{ device.device_name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="CheckIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      e("activate-status")
                    }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    <span v-if="device.status == '1'" class="badge bg-success">
                      {{ e("active") }}
                    </span>
                    <span v-if="device.status == '2'" class="badge bg-danger">
                      {{ e("stopped") }}
                    </span>
                    <span v-if="device.status == '0'" class="badge bg-warning">
                      {{ e("not-active") }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="LinkIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      e("connection-status")
                    }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    <span
                      v-if="device.last_update == null"
                      class="badge bg-warning"
                    >
                      {{ e("not-connected-yet") }}
                    </span>
                    <span v-if="device.last_update">
                      <span
                        v-if="
                          timeAgo(
                            device.last_update,
                            device.time_zone
                          ).includes('now') ||
                          timeAgo(
                            device.last_update,
                            device.time_zone
                          ).includes('second') ||
                          timeAgo(
                            device.last_update,
                            device.time_zone
                          ).includes('ثوان')
                        "
                        class="badge bg-success"
                      >
                        {{ e("connected-now") }}
                      </span>
                      <span
                        v-if="
                          !timeAgo(
                            device.last_update,
                            device.time_zone
                          ).includes('now') &&
                          !timeAgo(
                            device.last_update,
                            device.time_zone
                          ).includes('second') &&
                          !timeAgo(
                            device.last_update,
                            device.time_zone
                          ).includes('ثوان')
                        "
                        class="badge bg-secondary"
                      >
                        {{ e("last-seen") }}:
                        {{ timeAgo(device.last_update, device.time_zone) }}
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">IP</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ device.ip }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="CalendarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ e("exdate") }} </span>
                  </th>
                  <td class="pb-50">
                    <span v-if="device.demo == '1'" class="badge bg-danger">
                      {{ e("demo") }}
                    </span>
                    {{ cdate(device.exdate) }}
                  </td>
                </tr>
                <tr v-if="user.branches">
                  <th class="pb-50">
                    <feather-icon icon="HomeIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ e("branche") }}</span>
                  </th>
                  <td class="pb-50">
                    {{ brancheName(device.branche) }}
                  </td>
                </tr>
              </table>
            </b-col>
            <div class="col-12 g text-center">
              <button class="btn btn-relief-success" @click="refresh()">
                <i class="fa fa-refresh"></i>
                {{ e("reload-page") }}
              </button>
            </div>
          </b-row>
        </div>
      </div>
      <div class="col-12 col-lg-2">
        <div class="card card-body" style="height: 100%">
          <button
            v-b-modal.modal-1
            class="btn btn-lg text-center btn-outline-primary"
            style="width: 100%; height: 100%; border: none"
          >
            <img src="@/assets/images/worker.png" style="width: 60%" alt="" />
            <br /><br />
            <strong>{{ e("commands") }}</strong>
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-6 g">
        <div class="card">
          <div class="card-header">
            <h4>
              <i class="fa fa-list"></i>
              {{ e("device-log") }}
            </h4>
          </div>
          <div class="card-body">
            <div
              class="col-12 table-responsive"
              style="overflow-y: scroll; height: 600px"
            >
              <table class="table table-hover custom-table">
                <thead>
                  <th>
                    {{ e("date") }}
                  </th>
                  <th>
                    {{ e("content") }}
                  </th>
                  <th>
                    {{ e("action") }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="log in device.logs" :key="log._id">
                    <td>
                      {{ log.date }}
                    </td>
                    <td>
                      <span v-if="log.records.length > 1">
                        <textarea
                          name=""
                          class="form-control"
                          v-html="recordsValidate(log.records)"
                        ></textarea>
                      </span>
                      <span v-if="log.records.length == 1">
                        {{ log.records[0].id + " | " + log.records[0].time }}
                      </span>
                    </td>
                    <td>
                      {{ e(log.type) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 g">
        <div class="card">
          <div class="card-header">
            <h4>
              <i class="fa fa-times"></i>
              {{ e("rejected-reports") }}
            </h4>
          </div>
          <div class="card-body">
            <div
              class="col-12 table-responsive"
              style="overflow-y: scroll; height: 600px"
            >
              <table class="table table-hover custom-table">
                <thead>
                  <th>
                    {{ e("number") }}
                  </th>
                  <th>
                    {{ e("date") }}
                  </th>
                  <th>
                    {{ e("time") }}
                  </th>
                  <th>
                    {{ e("reason") }}
                  </th>
                </thead>
                <tbody>
                  <tr v-for="report in device.rejected" :key="report._id">
                    <td>
                      {{ report.number }}
                    </td>
                    <td>
                      {{ report.date }}
                    </td>
                    <td>
                      {{ report.time }}
                    </td>
                    <td>
                      {{ e("reason-" + report.reason) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      :title="e('commands')"
      ok-only
      size="lg"
      :ok-title="e('ok')"
    >
      <b-card-text>
        <div class="row g">
          <div class="col-12 col-lg-4 g">
            <button
              class="btn btn-lg text-center btn-outline-primary"
              style="width: 100%; height: 100%; border: none"
              @click="upload()"
            >
              <img src="@/assets/images/upload.png" style="width: 60%" alt="" />
              <br /><br />
              <strong
                >{{ e("syn") }} <br />
                <span class="text-danger">({{ e("upload") }})</span></strong
              >
            </button>
          </div>
          <div class="col-12 col-lg-4 g">
            <button
              class="btn btn-lg text-center btn-outline-primary"
              style="width: 100%; height: 100%; border: none"
              @click="download()"
            >
              <img
                src="@/assets/images/download.png"
                style="width: 60%"
                alt=""
              />
              <br /><br />
              <strong
                >{{ e("syn") }} <br />
                <span class="text-danger">({{ e("download") }})</span></strong
              >
            </button>
          </div>
          <div class="col-12 col-lg-4 g">
            <button
              class="btn btn-lg text-center btn-outline-primary"
              style="width: 100%; height: 100%; border: none"
              v-b-modal.modal-2
            >
              <img
                src="@/assets/images/upload2.png"
                style="width: 60%"
                alt=""
              />
              <br /><br />
              <strong
                >{{ e("upload-report-from-device") }} <br />
                <span class="text-danger"
                  >({{ e("date-or-between") }})</span
                ></strong
              >
            </button>
          </div>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-2">
      <b-card-text>
        <h4>1. {{ e("upload-custom-date") }}</h4>
        <div class="form-group">
          <label for="">{{ e("select-date") }}</label>
          <input
            v-if="!hijri"
            type="date"
            class="form-control"
            v-model="date_day"
          />
          <date-picker
            v-if="hijri"
            v-model="date_day"
            :locale="'ar-sa'"
            :calendar="'hijri'"
          ></date-picker>
          <span v-if="hijri">
            <h2 class="text-danger g text-cneter" v-if="date_day">
              {{ date_day }} هـ
            </h2>
          </span>
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="uploadReport()">
            <i class="fa fa-upload"></i>
            {{ e("upload") }}
          </button>
        </div>
        <hr />
        <h4>2. {{ e("upload-between") }}</h4>
        <div class="form-group">
          <label for="">{{ e("date-from") }}</label>
          <input
            v-if="!hijri"
            type="date"
            class="form-control"
            v-model="date_from"
          />
          <date-picker
            v-if="hijri"
            v-model="date_from"
            :locale="'ar-sa'"
            :calendar="'hijri'"
          ></date-picker>
          <span v-if="hijri">
            <h2 class="text-danger g text-cneter" v-if="date_from">
              {{ date_from }} هـ
            </h2>
          </span>
        </div>
        <div class="form-group">
          <label for="">{{ e("date-to") }}</label>
          <input
            v-if="!hijri"
            type="date"
            class="form-control"
            v-model="date_to"
          />
          <date-picker
            v-if="hijri"
            v-model="date_to"
            :locale="'ar-sa'"
            :calendar="'hijri'"
          ></date-picker>
          <span v-if="hijri">
            <h2 class="text-danger g text-cneter" v-if="date_to">
              {{ date_to }} هـ
            </h2>
          </span>
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="uploadReport2()">
            <i class="fa fa-upload"></i>
            {{ e("upload") }}
          </button>
        </div>
      </b-card-text>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BOverlay,
  VBModal,
  BCardText,
} from "bootstrap-vue";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import VueDatetimeJs from "vue-datetime-js";

const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default {
  components: {
    BCard,
    BButton,
    datePicker: VueDatetimeJs,
    BRow,
    BCol,
    BAvatar,
    BOverlay,
    VBModal,
    BCardText,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      device: {
        logs: [],
        rejected: [],
      },
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      date: "",
      date_day: null,
      date_from: null,
      date_to: null,
      hijri: false,
    };
  },
  created() {
    this.startup(0);
  },
  methods: {
    e(d) {
      return e(d);
    },
    startup(i) {
      var g = this;
      g.loading = true;
      $.post(api + "/devices/overview", {
        id: window.location.pathname.split("/overview/")[1],
        jwt: this.user.jwt,
      })
        .then(function (d) {
          d = JSON.parse(d);
          g.loading = false;
          if (d.status == 100) {
            g.device = d.response;
            if (d.response.device_name) {
              g.device.avatar = require("../../assets/images/devices/" +
                d.response.device_name +
                ".png");
            }
            if (i == 1) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("data-reloaded"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          } else {
            alert(e("error"));
            g.loading = false;
          }
        })
        .catch(function (e) {
          if(!e.includes('png')){
            alert("Error: " + e);
          }
        });
      if (cdate("2022-02-02").includes("ه")) {
        this.hijri = true;
      }
    },
    timeAgo(date, tz) {
      if (moment(date).tz(tz)) {
        return moment(date).lang(localStorage.getItem("lang")).tz(tz).fromNow();
      } else {
        return date;
      }
    },
    cdate: (date) => {
      return cdate(date);
    },
    brancheName(id) {
      var title = e("notfound");
      this.user.branches_list.forEach((b) => {
        if (b.id == id) {
          title = b.title;
        }
      });
      return title;
    },
    uploadReport() {
      var date = this.date_day;
      if (date) {
        if (cdate("2222-22-22").includes("ه")) {
          date = a2e(date);
          date = moment2(date, "iYYYY/iM/iD").format("YYYY-MM-DD");
        }
        var _g = this;
        this.$bvModal.hide("modal-2");
        this.$bvModal.hide("modal-1");
        $.post(api + "/devices/upload-report-from-device", {
          jwt: this.user.jwt,
          sn: this.device.serial_number,
          from: date + " 01:00:00",
          to: date + " 23:59:59",
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("command-sent"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("error"),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      } else {
        alert(e("please-select-date"));
      }
    },
    uploadReport2() {
      var date1 = this.date_from;
      var date2 = this.date_to;
      if (date1 && date2) {
        if (cdate("2222-22-22").includes("ه")) {
          date1 = a2e(date1);
          date1 = moment2(date1, "iYYYY/iM/iD").format("YYYY-MM-DD");
          date2 = a2e(date2);
          date2 = moment2(date2, "iYYYY/iM/iD").format("YYYY-MM-DD");
        }
        var _g = this;
        this.$bvModal.hide("modal-2");
        this.$bvModal.hide("modal-1");
        $.post(api + "/devices/upload-report-from-device", {
          jwt: this.user.jwt,
          sn: this.device.serial_number,
          from: date1 + " 01:00:00",
          to: date2 + " 23:59:59",
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("command-sent"),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("error"),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      } else {
        alert(e("please-select-date"));
      }
    },
    refresh() {
      this.startup(1);
    },
    upload() {
      var _g = this;
      if (confirm(e("confirm"))) {
        $.post(api + "/devices/upload", {
          jwt: this.user.jwt,
          sn: this.device.serial_number,
        })
          .then(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("command-sent"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch(function (e) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    download() {
      var _g = this;
      if (confirm(e("confirm"))) {
        $.post(api + "/devices/download", {
          jwt: this.user.jwt,
          sn: this.device.serial_number,
        })
          .then(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("command-sent"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch(function (e) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    recordsValidate(arr) {
      var n = "";
      arr.forEach(function (a) {
        n = n + `${a.id} | ${a.time}\n`;
      });
      return n;
    },
  },
};
</script>

<style>
</style>
